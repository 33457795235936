.createorder__container {
  position: absolute;
  top: 60px;
  left: 60px;
  width: calc(100vw - 60px);
  height: calc(100vh - 100px);
  /* background-color: #e0e0e0; */
  /* border: 1px solid black; */
}
.createorder__insidecontainer {
  /* border: 1px solid black; */
  width: 90%;
  height: 90%;
  margin-left: 90px;
}
.createorder__upperbar {
  display: flex;
  width: 1295px;
  height: 15px;
  align-items: center;
  /* margin-left: 108px; */
  margin-top: 18px;
  margin-bottom: 18px;
  justify-content: space-between;
}
.createorder__upperbar > h2 {
  font-size: 16px;
}
.Container {
  height: 530px;
  width: 1295px;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  /* margin-top: 100px; */
  /* margin-left: 108px; */
}
::-webkit-scrollbar {
  display: none;
}
.Container__header {
  position: relative;
  height: 3em;
  background-color: black;
  border: 1px solid #2f2f2f;
}
.Container__header1 {
  position: absolute;
  width: 162px;
  height: 27.3px;
  color: white;
  
  top: 14px;
  font-weight: 600;
}
.Container__header4 {
  position: absolute;
  width: 162px;
  height: 27.3px;
  color: white;
  left: 1000px;
  top: 14px;
  font-weight: 600;
}
.Container__header2 {
  position: absolute;
  width: 162px;
  height: 27.3px;
  color: white;
  left: 325px;
  top: 14px;
  font-weight: 600;
}
.Container__header3 {
  position: absolute;
  width: 162px;
  height: 27.3px;
  color: white;
  left: 550px;
  top: 14px;
  font-weight: 600;
}
.createorder__buttons {
  display: flex;
  /* margin-left: 108px; */
  justify-content: flex-end;
  align-items: center;
  width: 1295px;
  height: 50px;
}
.createorder__buttons__div {
  display: flex;
  justify-content: space-between;
  width: 231px;
}
.createorder__buttons__div > button {
  width: 107px;
  height: 32px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}
.cancel-btn {
  background-color: rgb(204, 2, 2);
  color: #ffffff;
  border: 1px solid rgb(204, 2, 2);
}
.cancel-btn:hover{
  background-color: rgb(170, 1, 1);
  color: #ffffff;
  border: 1px solid rgb(170, 1, 1);
}
.proceed {
  background-color: #5861ae;
  color: white;
  border: 1px solid #5861ae;
}
.proceed:hover{
  background-color: #434c9b;
  color: white;
  border: 1px solid #4049a1;
}
.searchbar {
  border: none;
  outline: none;
  /* border-bottom: 1px solid grey; */
}
.searchbar__div {
  border-bottom: 1px solid #a0a0a0;
}
