.orders-class{

    width: calc(100vw - 60px);
    height: calc(100vh - 100px);
    position: absolute;
    top: 60px;
    left: 60px;
    overflow: hidden;

}
.header-order{
    display: flex;
    justify-content: space-between;
}
.middle-of-orders{
   

    margin: auto ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
   
}

.orders,.search-bar{
    margin-top: 10px;
    margin-left:  10px;

}
.search-bar input{
    font-size: 20px;
    padding-left: 5px;
}
.orders{
    font-weight: 600;
    font-size: 25px;
}
.orders span{
    margin-left: 5px;
}
.btn-1{
    border: blue 1px solid;
    color: blue;
    padding: 10px 30px;
}

.search-bar{
    margin-right: 10px;
    border-bottom: 1px solid black;
}
.search-bar input{
    border: none;
    
    background: none;
    outline: none;
}
.middle-of-orders h3{
    color: grey;
}
.wash-type{
    display: flex;
    align-items: center;
}