.singleproduct__container {
  height: 79px;
  display: flex;
  border-bottom: 1px solid rgba(70, 70, 70, 0.3);
  /* opacity: 0.2; */
}

.single__productdetails > h3 {
  font-size: 14px;
  margin-bottom: 6px;
  color: #5861ae;
}
/* .singleproduct__price > p {
  font-size: 10px;
  color: #76788b;
} */
.singleproduct__product {
  display: flex;
  height: 43px;
  margin-top: 21px;
  margin-left: 23px;
}
.single__productdetails {
  margin-left: 9px;
}
.single__productdetails > h3 {
  color: #1d2022;
  font-size: 14px;
}
.single__productdetails > p {
  width: 130px;
  color: #76788b;
  font-size: 10px;
}
.singleproduct__quantity {
  width: 65px;
  height: 28px;
  margin-left: 170px;
  margin-top: 31.5px;
}
/* .singleproduct__price > h1 {
  font-size: 24px;
  color: #5861ae;
} */
.singleproduct__mainPrice__div {
  color: #5861ae;
  font-size: 24px;
  font-weight: 500;
}
.singleproduct__Price__div {
  font-size: 18px;
  color: #221919;
  margin-right: 11px;
}
/* .singleproduct__price > p {
  font-size: 18px;
  color: #221919;
  margin-right: 11px;
} */
.singleproduct__quantity > input {
  border: 1px solid rgba(186, 186, 186, 0.73);
  color: #605959;
  width: 63px;
  height: 26px;
  outline: #5861ae;
}
.singleproduct__quantity > input:focus {
  border: 2px solid rgba(88, 97, 174, 1);
}
.singleproduct__widgets {
  display: flex;
  justify-content: space-between;
  width: 347px;
  height: 29.37px;
  margin-left: 150.37px;
  margin-top: 30.86px;
}
.singleproduct__widgets > img {
  width: 25px;
  height: 29px;
  cursor: pointer;
}
.product {
  width: 45px;
  height: 43px;
}
.widgets {
  width: 25.26px;
  height: 29.37px;
}
.singleproduct__price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 80.63px;
  margin-top: 31.5px;
  width: 126px;
  height: 34px;
  /* border: 1px solid #e0e0e0; */
}
.reset {
  width: 81px;
  height: 32px;
  margin-top: 31px;
  margin-left: 60px;
  border: 1px solid #5861ae;
  color: #5861ae;
  background-color: white;
  cursor: pointer;
}
.reset:hover{
  border: 1px solid #5861ae;
  color: #f8f9fc;
  background-color: #5861ae;
}