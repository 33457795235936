.summary__conatiner1 {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0;
  /* overflow-y: scroll; */
  /* background: rgba(0, 0, 0, 0.4); */
}
.summary__leftdiv {
  position: absolute;
  background: white;
  margin-left: calc(100vw - 926px);
  width: 926px;
  height: 100vh;
}
.summary__header {
  display: flex;
  align-items: center;
  height: 50px;
  background-color: #5861ae;
}
.summary__header__innnerdiv {
  /* border: 1px solid white; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  color: white;
}
.summary__header__innnerdiv > h1 {
  font-size: 22px;
  font-weight: 400;
  margin-left: 18px;
}
.summary__header__innnerdiv > i {
  margin-right: 27px;
  cursor: pointer;
}
.summary__footer1 {
  top: calc(100vh - 50px);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 926px;
  height: 50px;
}

.summary__storeselector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 926px;
  background-color: #f4f9ff;
  border: 1px solid rgba(112, 112, 112, 0.2);
}
.summary__storeselector > div > h4 {
  font-size: 16px;
  font-weight: 600;
}
.summary_StoreSelector__leftdiv {
  margin-left: 43px;
  /* border: 1px solid black; */
}
.summary_StoreSelector__rightdiv {
  margin-right: 255px;
  /* border: 1px solid black; */
}
.summary__storeselector__form {
  width: 203px;
  height: 27px;
  margin-left: 30px;
  border: none;
  border-bottom: 1px solid #3e3f40;
  background: transparent;
  outline: none;
  font-size: 16px;
  letter-spacing: 1.3px;
}
nav.summary__storeselector{
  padding-top:10px;
}
.summary__storeselector__phone {
  margin-right: 220px;
  font-size: 16px;
  
}
.summary__storeselector__address > h3 {
  font-size: 16px;
}
.summary__storeselector__address > p {
  font-size: 16px;
}
.summary__storeselector__phone > h3 {
  font-size: 16px;
}
.summary__storeselector__phone > p {
  font-size: 16px;
}
.summary__heading {
  width: 95px;
  height: 19px;
  font-size: 14px;
  margin-left: 33px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.summary__heading > h2 {
  font-size: 14px;
}
.summary__orderdetails__container1 {
  /* border: 1px solid black; */
  width: 926px;
  min-height: auto;
  max-height: 420px;
  overflow-y: scroll;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
.summary__address__container {
  height: 120px;
}
.summary__orderdetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  width: 781px;
  margin-left: 45px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
.summary__orderdetails > h1 {
  font-size: 18px;
  font-weight: 500;
}
.summary__orderdetails > h3 {
  font-size: 16px;
  color: #1b2734;
}
.summary__orderdetails > p {
  font-size: 16px;
  color: #1b2734;
}
.summary__subtotal {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 781px;
  height: 40px;
  margin-left: 45px;
  /* border: 1px solid black; */
}
.summary__subtotal > h2 {
  font-size: 16px;
  /* margin-right: 10px; */
}

.summary__pickupcharges {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 781px;
  height: 40px;
  margin-left: 45px;
}
.summary__pickupcharges > h2 {
  font-size: 16px;
}
.summary__totalprice {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 781px;
  height: 40px;
  margin-left: 45px;
  margin-bottom: 15px;
  background-color: #5861ae;
}
.summary__subtotal > h1 {
  font-size: 18px;
}
.summary__pricediv {
  height: 60px;
  /* border: 1px solid black; */
  display: flex;
  margin-right: 20px;
  align-items: center;
}
.summary__priceparticulars {
  font-size: 18px;
  color: #1b2734;
  margin-right: 40px;
}
.summary__mainPrice {
  color: #5861ae;
  font-size: 20px;
}
.summary__priceparticulars__mainprice {
  color: white;
  margin-right: 40px;
  font-size: 18px;
}
.summary__mainPrice__mainprice {
  color: white;
  font-size: 22px;
}
.summary__address__container > h4 {
  font-size: 14px;
  margin-left: 35px;
  margin-top: 10px;
}
.summary__address {
  width: 236px;
  height: 84px;
  margin-top: 20px;
  margin-left: 35px;
  border: 1px solid #b7b7b7;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  border-radius: 5px;
}
.summary__address > img {
  visibility: hidden;
}
.summary__address--active {
  width: 236px;
  height: 84px;
  margin-top: 20px;
  margin-left: 35px;
  border: 1px solid #5861ae;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
}
.summary__address--active > img {
  visibility: visible;
  margin-top: 5px;
  margin-left: 210px;
}

.multi_stepper {
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 4em;
  border-bottom: 1px solid rgba(112, 112, 112, 0.2);
}
.multi_stepper::after {
  content: "";
  position: absolute;

  transform: translateY(100%);
  height: 2px;
  width: 810px;
  background-color: #b7b7b7;
  margin-top: 1.5em;
}
.multi-stepper-tags {
  margin-top: 1em;
  width: 20px;
  height: 20px;
  background-color: #b7b7b7;

  border-radius: 50%;
  border: 1px solid;
}
.track-span {
  margin-top: 1.5em;
}
.cancel {
  border: none;
  cursor: pointer;
  margin-right: 30.25px;
  width: 150px;
  height: 42px;
  border-radius: 4px;
  background-color: #f41313;
  color: white;
  font-size: 16px;
}
.cancel:hover{
  background-color: #aa0303;
}
